import { Box, Button } from '@mui/material'
import React, { useEffect } from 'react'
import { useFieldArray } from 'react-hook-form'
import FeatureField from './FeatureField'

const FeaturesBlock = ({ control, checkReset }) => {
    const { fields, append, remove, replace } = useFieldArray({
        control,
        name: 'features',
    })

    useEffect(() => {
        if (fields.length === 0) append({ title: '', feature: '' })
    }, [])

    useEffect(() => {
        if (fields.length !== 0) replace()
    }, [checkReset])

    return (
        <Box display="flex" flexDirection="column">
            {fields.map((field, index) => (
                <FeatureField
                    key={field.id}
                    control={control}
                    name={`features[${index}]`}
                    field={field}
                    onRemove={() => remove(index)}
                />
            ))}
            <Button
                variant="outlined"
                color="primary"
                sx={{ maxWidth: '200px', mt: 2, alignSelf: 'center' }}
                onClick={() => append({ title: '', feature: '' })}
            >
                Add Feature
            </Button>
        </Box>
    )
}

export default FeaturesBlock
