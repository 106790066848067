import { Button, Container, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import React, { useState } from 'react'
import SearchBlock from '../components/EditProductTest/SearchBlock'
import { getProduct } from '../redux/slices/productSlice/productAsync'
import { STATUS } from '../common/utils/setStatus'

const EditCategory = () => {
    const dispatch = useDispatch()
    const [codeProduct, setCodeProductValue] = useState('')
    const { status, category, subcategory } = useSelector(
        ({ product }) => product
    )

    const handleSearchProduct = () => {
        if (codeProduct?.length > 4 && codeProduct?.length < 11)
            dispatch(getProduct({ code: codeProduct.trim() }))
    }

    const isLoading =
        status.find((el) => el.name === 'getCategoriesSubCategories')
            ?.status === STATUS.loading

    return (
        <Container maxWidth="md">
            <Typography variant="h3" color="initial" pb={2}>
                Редактирование категорий и подкатегории
            </Typography>
            {/* <SearchBlock
                codeProduct={codeProduct}
                setCodeProduct={setCodeProductValue}
                handleSearchProduct={handleSearchProduct}
                isLoading={isLoading}
            /> */} 
            <Button>Получить список</Button>
        </Container>
    )
}

export default EditCategory
