import React from 'react'
import AdbIcon from '@mui/icons-material/Adb'
import { Box } from '@mui/material'
import { Link } from 'react-router-dom'
import MyButton from '../MyButton'

const LogoBlockAdmin = ({ mobile = false }) => {
    const display = mobile
        ? { xs: 'flex', md: 'none' }
        : { xs: 'none', md: 'flex' }

    return (
        <Box display="flex" alignItems="center">
            <AdbIcon
                sx={{
                    display,
                    mr: 1,
                    color: 'white',
                }}
            />

            <MyButton
                text="Ellemod"
                component={Link}
                to="/admin"
                variant="outlined"
                textColor="white"
                upperCase
                sx={{
                    mr: 2,
                    display,
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.1px',
                    color: 'white',
                    textDecoration: 'none',
                }}
            />
        </Box>
    )
}

export default LogoBlockAdmin
