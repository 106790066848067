import { configureStore } from '@reduxjs/toolkit'
import categorySlice from './slices/categorySlice/categorySlice'
import productSlice from './slices/productSlice/productSlice'
import userAdminSlice from './slices/userSlice/userSlice'

export const store = configureStore({
    reducer: {
        userAdmin: userAdminSlice,
        product: productSlice,
        category: categorySlice,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})
