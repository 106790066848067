import * as yup from 'yup'

export const schemaAddCategory = yup
    .object({
        enName: yup.string().required('Category is required'),
        ruName: yup.string().required('Category is required'),
        ukName: yup.string().required('Category is required'),
    })
    .required()

export const schemaAddSubCategory = yup
    .object({
        docNameCategory: yup.string().required('Category is required'),
        enName: yup.string().required('Subcategory is required'),
        ruName: yup.string().required('Subcategory is required'),
        ukName: yup.string().required('Subcategory is required'),
    })
    .required()
