/* eslint-disable max-len */
import * as yup from 'yup'

const schemaEditProduct = yup.object({
    name: yup.string().required('Именной продукт обязателен'),
    brand: yup.string().required('Бренд обязателен'),
    price: yup.number().required(),
    category: yup.string().required('Категория обязательна'),
    count: yup.number().required(),
    descriptions: yup.array().of(
        yup.object().shape({
            title: yup.string().required('Название обязательно'),
            paragraphs: yup
                .array()
                .min(1, 'Необходимо наличие как минимум одного абзаца')
                .of(yup.string().required('Требуется содержание параграфа')),
        })
    ),
    features: yup.array().of(
        yup.object().shape({
            title: yup.string().required('Название обязательно'),
            feature: yup.string().required('Требуется характеристика'),
        })
    ),
    image: yup
        .mixed()
        .test('required', 'Пожалуйста, выберите изображения', (files) => {
            if (!files || files.length === 0) return true
            for (let i = 0; i < files.length; i++) {
                const fileType = files[i].type.split('/')[0]
                if (fileType !== 'image') {
                    return false
                }
            }
            return true
        })
        .test(
            'required',
            'Один или несколько файлов имеют слишком большой размер, максимальный размер 1Mb',
            (files) => {
                if (!files || files.length === 0) return true
                for (let i = 0; i < files.length; i++) {
                    const fileSizeInMb = files[i].size / 1024 / 1024
                    if (fileSizeInMb > 1) {
                        return false
                    }
                }
                return true
            }
        )
        .test('required', 'Допускаются только файлы PNG', (files) => {
            if (!files || files.length === 0) return true
            for (let i = 0; i < files.length; i++) {
                const fileExtension = files[i].name
                    .split('.')
                    .pop()
                    .toLowerCase()
                if (fileExtension !== 'png') {
                    return false
                }
            }
            return true
        }),
})

export default schemaEditProduct
