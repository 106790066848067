import { Box, Button, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import React from 'react'

const LogOut = ({
    handleOpenUserMenu,
    anchorElUser,
    handleLogOut,
    handleCloseUserMenu,
}) => {
    return (
        <Box>
            <Tooltip title="Open settings">
                <Button
                    onClick={handleOpenUserMenu}
                    variant="contained"
                    color="info"
                    sx={{
                        p: '5px 20px',
                        color: 'white',
                        fontSize: '17px',
                        textTransform: 'initial',
                    }}
                >
                    Admin
                </Button>
            </Tooltip>
            <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                <MenuItem onClick={handleLogOut}>
                    <Typography textAlign="center">Log Out</Typography>
                </MenuItem>
            </Menu>
        </Box>
    )
}

export default LogOut
