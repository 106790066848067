import { createSlice } from '@reduxjs/toolkit'
import { getAuthAdminUser, logOutUserAdmin } from './userAsync'

const initialState = {
    displayName: null,
    photoURL: null,
    email: null,
    token: null,
    id: null,
    isLoading: false,
    error: null,
}

const userSlice = createSlice({
    name: 'userAdmin',
    initialState,
    reducers: {
        setUserAdmin(state, action) {
            state.displayName = action.payload.displayName
            state.photoURL = action.payload.photoURL
            state.email = action.payload.email
            state.token = action.payload.accessToken
            state.id = action.payload.uid
        },
        removeUserAdmin(state) {
            state.displayName = null
            state.photoURL = null
            state.email = null
            state.token = null
            state.id = null
        },
    },
    extraReducers: (builder) => {
        // getAuthUser
        builder.addCase(getAuthAdminUser.pending, () => {
            return { ...initialState, isLoading: true }
        })
        builder.addCase(getAuthAdminUser.fulfilled, (state, action) => {
            state.isLoading = false
            state.displayName = action.payload.displayName
            state.photoURL = action.payload.photoURL
            state.email = action.payload.email
            state.token = action.payload.accessToken
            state.id = action.payload.uid
        })
        builder.addCase(getAuthAdminUser.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.payload
        })

        // logOutUser
        builder.addCase(logOutUserAdmin.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(logOutUserAdmin.fulfilled, (state) => {
            state.isLoading = false
            state.displayName = null
            state.photoURL = null
            state.email = null
            state.token = null
            state.id = null
        })
        builder.addCase(logOutUserAdmin.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.payload
        })
    },
})

export const { setUserAdmin, removeUserAdmin } = userSlice.actions

export default userSlice.reducer
