import { yupResolver } from '@hookform/resolvers/yup'
import SendIcon from '@mui/icons-material/Send'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Button, Container, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { doc } from 'firebase/firestore'
import { useDispatch, useSelector } from 'react-redux'
import DescriptionBlock from '../components/EditProductTest/DescriptionBlock/DescriptionBlock'
import FeaturesBlock from '../components/EditProductTest/FeaturesBlock/FeaturesBlock'
import ImagesBlock from '../components/EditProductTest/ImagesBlock'
import MainBlock from '../components/EditProductTest/MainBlock'
import schemaEditProduct from '../components/EditProductTest/schemaEditProduct'
import MyButton from '../components/MyButton'
import MyDividerChip from '../components/MyDividerChip'
import { db } from '../firebase'
import {
    editProduct,
    getProduct,
} from '../redux/slices/productSlice/productAsync'
import { CATEGORIES } from '../common/consts/consts'
import { STATUS } from '../common/utils/setStatus'
import SearchBlock from '../components/EditProductTest/SearchBlock'
import LinearIndeterminate from '../components/LinearIndeterminate'
import {
    setProduct,
    setStatusInit,
} from '../redux/slices/productSlice/productSlice'
import checkStatus from '../common/utils/checkStatus'

const EditProduct = () => {
    const dispatch = useDispatch()

    const form = useRef()

    const [files, setFiles] = useState([])
    const [editInputName, setEditInputName] = useState('')
    const [checkReset, setCheckReset] = useState(false)
    const [filteredSubcategories, setFilteredSubcategories] = useState([])
    const [codeProduct, setCodeProductValue] = useState('')
    const { status, product } = useSelector(({ product }) => product)
    const isLoading =
        status.find((el) => el.name === 'getProduct')?.status === STATUS.loading

    const handleSearchProduct = () => {
        if (codeProduct?.length > 4 && codeProduct?.length < 11)
            dispatch(getProduct({ code: codeProduct.trim() }))
    }

    const handleClickEditInput = (inputName) => {
        setEditInputName(inputName)
    }

    const { categories, subcategories } = useSelector(
        ({ category }) => category
    )

    const editLoading =
        checkStatus(status, 'editProduct')?.status === STATUS.loading

    const editSuccess =
        checkStatus(status, 'editProduct')?.status === STATUS.success

    const {
        control,
        formState: { errors },
        handleSubmit,
        register,
        getValues,
        watch,
        setValue,
        reset,
        // resetField,
    } = useForm({
        resolver: yupResolver(schemaEditProduct),
    })

    const watchFieldsImage = watch('image')
    const watchFieldsCategory = watch('category')
    // const watchFields = watch()

    useEffect(() => {
        if (editSuccess) {
            restForm()
            dispatch(setStatusInit('editProduct'))
            setFiles([])
            dispatch(setProduct(null))
        }
    }, [status])

    useEffect(() => {
        return () => {
            dispatch(setProduct(null))
            reset()
        }
    }, [])

    const restForm = () => {
        reset()
        setCheckReset(!checkReset)
        setFiles([])
    }

    const onSubmit = (data) => {
        const formData = { ...data }
        let isLoadImg = true
        if (!formData.image?.length) {
            isLoadImg = false
            formData.image = product.image
        }

        dispatch(editProduct({ formData, isLoadImg }))
    }

    // Subcategory filter, with the selected category
    useEffect(() => {
        const checkCategory =
            categories?.length && subcategories?.length && getValues().category

        setFilteredSubcategories(
            checkCategory
                ? subcategories.filter(
                      (el) =>
                          el.category.path ===
                          doc(db, CATEGORIES, getValues().category).path
                  )
                : []
        )
        // restSubcategory()
    }, [watchFieldsCategory])

    // Restoration of these forms, after returning to the page
    useEffect(() => {
        if (product) {
            Object.keys(product).forEach(
                (key) => key !== 'image' && setValue(key, product[key])
            )
            setValue('category', product.category.nameDoc)
            setValue('subcategory', product.subcategory.nameDoc)
        }
    }, [product])

    // filelist processing to display a photo of the product
    useEffect(() => {
        if (getValues()?.image?.length) {
            const fileList = getValues().image

            const images = []

            for (let i = 0; i < fileList?.length; i++) {
                const file = fileList[i]
                const imageUrl = URL.createObjectURL(file)
                images.push(imageUrl)
            }

            setFiles(() => {
                return images
            })
        }
    }, [watchFieldsImage])

    return (
        <Container maxWidth="md">
            <Typography variant="h3" color="initial" pb={2}>
                Edit product
            </Typography>
            <SearchBlock
                codeProduct={codeProduct}
                setCodeProduct={setCodeProductValue}
                handleSearchProduct={handleSearchProduct}
                isLoading={isLoading}
            />
            {isLoading && <LinearIndeterminate />}
            {product ? (
                <Box
                    ref={form}
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                    sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
                >
                    {/* Main */}
                    <MyDividerChip label="Main" />
                    <MainBlock
                        editInputName={editInputName}
                        handleClickEditInput={handleClickEditInput}
                        control={control}
                        categories={categories}
                        subcategories={filteredSubcategories}
                        setValue={setValue}
                        getValues={getValues}
                    />
                    {/* Features */}
                    <MyDividerChip label="Features" />
                    <FeaturesBlock
                        control={control}
                        checkReset={checkReset}
                        setCheckReset={setCheckReset}
                        editInputName={editInputName}
                        handleClickEditInput={handleClickEditInput}
                    />
                    {/* Description */}
                    <MyDividerChip label="Description" />
                    <DescriptionBlock
                        control={control}
                        checkReset={checkReset}
                        setCheckReset={setCheckReset}
                        editInputName={editInputName}
                        handleClickEditInput={handleClickEditInput}
                    />
                    {/* Images */}
                    <MyDividerChip label="Images" />
                    <Button
                        variant="contained"
                        component="label"
                        color="inherit"
                        sx={{
                            color: 'black',
                            width: '100%',
                        }}
                    >
                        <input
                            {...register('image')}
                            type="file"
                            accept="image/png"
                            name="image"
                            multiple
                            hidden
                        />
                        Загрузить изображения (только .png)
                    </Button>
                    {errors?.image && (
                        <Typography
                            variant="h6"
                            color="error"
                            sx={{ fontSize: 12 }}
                        >
                            {errors.image.message}
                        </Typography>
                    )}

                    {files?.length > 0 ? (
                        <>
                            <Typography
                                variant="h4"
                                color="text.secondary"
                                pb={2}
                            >
                                Примерный образец того, как будут выглядеть
                                изображения в карточки будут выглядеть следующим
                                образом
                            </Typography>
                            <ImagesBlock files={files} product={getValues()} />
                        </>
                    ) : (
                        product && (
                            <>
                                <Typography
                                    variant="h4"
                                    color="text.secondary"
                                    pb={2}
                                >
                                    The approximate example of what pictures in
                                    the cards will look like
                                </Typography>
                                <ImagesBlock
                                    files={product.image}
                                    product={getValues()}
                                />
                            </>
                        )
                    )}

                    <MyDividerChip label="Send" />
                    <Grid container spacing={1}>
                        <Grid xs={4}>
                            <MyButton
                                text="Сбросить форму"
                                fullWidth
                                color="error"
                                onClick={() => restForm()}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid xs={8}>
                            <LoadingButton
                                sx={{ height: '100%', color: 'white' }}
                                fullWidth
                                type="submit"
                                endIcon={<SendIcon />}
                                loading={editLoading}
                                loadingPosition="end"
                                variant="contained"
                            >
                                <span>Отправить изменения</span>
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Box>
            ) : null}
        </Container>
    )
}

export default EditProduct
