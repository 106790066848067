import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { Link } from 'react-router-dom'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { useNavigate } from 'react-router'
import { Card, CardActions, CardContent, Divider, Paper } from '@mui/material'
import { useDispatch } from 'react-redux'
import ImgBlock from '../ImgBlock'
import BuyButton from './BuyButton'
// import {
//     addProduct,
//     removeProduct,
// } from '../../../redux/slices/cartSlice/cartSlice'
// import ImgBlock from '../../ImgBlock'

const catString = (str, maxLength) => {
    if (str.length > maxLength) return `${str.slice(0, maxLength)}...`
    return str
}

const CardItemProduct = ({ product }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // const handleDelete = (remove) => {
    //     dispatch(removeProduct({ product, remove }))
    // }
    // const handleAdd = () => {
    //     dispatch(addProduct(product))
    // }

    const handleClickProduct = () => {
        navigate(`ellemod.com.ua/product/${product.code}`)
    }

    return (
        <Card
            sx={(theme) => ({
                maxWidth: '100%',
                border: '1px solid rgba(230, 230, 230, 1)',
                borderRadius: '10px',
                p: 0,
                '&:hover': {
                    boxShadow: theme.shadows[4],
                },
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'stretch',
            })}
            elevation={0}
        >
            <Box sx={{ flex: 1 }}>
                <Link
                    target="_blank"
                    to={`https://ellemod.com.ua/product/${product.code}`}
                >
                    <ImgBlock
                        src={product.image[0]}
                        // handleClickProduct={handleClickProduct}
                        sx={{ cursor: 'pointer' }}
                    />
                </Link>
            </Box>

            <Divider />
            <CardContent
                sx={{
                    // p: 2,
                    pb: 0,
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Typography
                    gutterBottom
                    variant="p"
                    component="p"
                    sx={{
                        fontSize: '15px',
                        fontWeight: 600,
                        color: '#4d4d4d',
                        flex: 1,
                        cursor: 'pointer',
                        a: {
                            color: '#4d4d4d',
                            textDecoration: "none"
                        },
                    }}
                    // onClick={handleClickProduct}
                >
                    <Link
                        target="_blank"
                        to={`https://ellemod.com.ua/product/${product.code}`}
                    >
                        {catString(product.name, 40)}
                    </Link>
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        gap: 1,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        gutterBottom
                        variant="p"
                        component="p"
                        color="primary"
                        sx={{
                            fontSize: '19px',
                            fontWeight: 600,
                            m: 0,
                        }}
                    >
                        {product.price} ₴
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <BuyButton product={product} />
                    </Box>
                </Box>
            </CardContent>
            <CardActions sx={{ display: 'flex', gap: 1 }} disableSpacing>
                {/* <BuyButton product={product} /> */}

                {/* <Button size="small" variant="contained">
                      <FavoriteBorderIcon
                          sx={{
                              color: 'white',
                              fontSize: '23px',
                              height: '100%',
                          }}
                      />
                  </Button> */}
            </CardActions>
        </Card>
    )
}

export default CardItemProduct
