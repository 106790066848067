import { TextField } from '@mui/material'
import React from 'react'

const ControlTextField = ({
    nameUpper,
    pb = 0,
    pt = 0,
    error,
    field,
    autoComplete = 'off',
    label = '',
    multiline = false,
    sx = {},
    TextFieldProps = {},
    editInputName,
    editButton,
    name,
}) => {
    return (
        <TextField
            autoComplete={autoComplete}
            label={label || nameUpper}
            multiline={multiline}
            minRows={multiline ? 3 : undefined}
            error={!!error}
            helperText={error ? error.message : null}
            variant="outlined"
            placeholder={label || nameUpper}
            fullWidth
            // disabled={editInputName !== name && editButton}
            FormHelperTextProps={{
                sx: {
                    fontSize: '12px',
                },
            }}
            sx={{
                mb: pb,
                pt,
                boxShadow:
                    editInputName === name &&
                    editButton &&
                    '0px 0px 3px 1px orange',
                ...sx,
            }}
            {...field}
            {...TextFieldProps}
        />
    )
}

export default ControlTextField
