import { Box, Button } from '@mui/material'
import React, { useEffect } from 'react'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { useFieldArray } from 'react-hook-form'
import ControlInput from '../../ControlInput/ControlInput'

const DescriptionField = ({ control, name, onRemove }) => {
    const { fields, append, remove } = useFieldArray({
        control,
        name: `${name}.paragraphs`,
    })
    useEffect(() => {
        if (fields.length === 0) append('')
    }, [])
    return (
        <Box
            sx={(theme) => ({
                padding: 2,
                background: theme.palette.background.lightGrey,
                border: '1px solid lightGrey',
                mt: 1,
            })}
        >
            <Grid spacing={1}>
                <Grid container spacing={1}>
                    <Grid xs={12}>
                        <ControlInput
                            autoComplete="on"
                            name={`${name}.title`}
                            control={control}
                            label="Title"
                            sx={{ background: 'white' }}
                        />
                    </Grid>
                    <Grid xs={12}>
                        {fields.map((paragraph, index) => (
                            <Grid
                                key={paragraph.id}
                                container
                                spacing={1}
                                alignItems="center"
                            >
                                <Grid xs={12} md={10}>
                                    <ControlInput
                                        autoComplete="on"
                                        name={`${name}.paragraphs[${index}]`}
                                        control={control}
                                        label={`Paragraph ${index + 1}`}
                                        multiline
                                        rows={3}
                                        sx={{ background: 'white' }}
                                    />
                                </Grid>
                                <Grid xs={2} md={2}>
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        color="error"
                                        onClick={() => remove(index)}
                                    >
                                        Delete
                                    </Button>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

                <Grid xs={12}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            px: 0.5,
                            pt: 1,
                        }}
                    >
                        <Button
                            variant="outlined"
                            color="inherit"
                            onClick={() => append('')}
                        >
                            Add Paragraph
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={onRemove}
                        >
                            Remove Block
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default DescriptionField
