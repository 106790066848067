import React, { useEffect, useState } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { useDispatch } from 'react-redux'
import { Box } from '@mui/material'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import AppRouter from '../AppRouter'
import MyAppBar from './MyAppBar/MyAppBar'
import {
    removeUserAdmin,
    setUserAdmin,
} from '../redux/slices/userSlice/userSlice'
import SimplePreloader from './SimplePreloader'
import { getCategoriesSubcategories } from '../redux/slices/categorySlice/categoryAsync'

export default function Layout() {
    const dispatch = useDispatch()
    const [isAuth, setIsAuth] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const auth = getAuth()

    useEffect(() => {
        dispatch(getCategoriesSubcategories())
    }, [])

    useEffect(() => {
        setLoading(true)
        onAuthStateChanged(auth, (user) => {
            if (!user) {
                dispatch(removeUserAdmin())
                setIsAuth(false)
            } else {
                dispatch(setUserAdmin(user))
                setIsAuth(true)
            }
            setLoading(false)
        })
    }, [])

    if (isLoading) {
        return <SimplePreloader />
    }

    return (
        <>
            <CssBaseline />
            {isAuth && <MyAppBar />}
            <Box pt={2} pb={3}>
                <AppRouter isAuth={isAuth} />
            </Box>
        </>
    )
}
