import React from 'react'
import { Box, IconButton, Menu, MenuItem } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { Link } from 'react-router-dom'
import MyButton from '../MyButton'
import {
    ADD_CATEGORY_ROUTE,
    ADD_PRODUCT_ROUTE,
    DELETE_CATEGORY_ROUTE,
    DELETE_PRODUCT_ROUTE,
    EDIT_CATEGORY_ROUTE,
    EDIT_PRODUCT_ROUTE,
} from '../../common/consts/ROUTES'

const MobileMenu = ({ handleOpenNavMenu, anchorElNav, handleCloseNavMenu }) => {
    return (
        <Box
            sx={{
                display: { xs: 'flex', md: 'none' },
            }}
        >
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
            >
                <MenuIcon />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                    display: { xs: 'block', md: 'none' },
                }}
            >
                <MenuItem
                    onClick={handleCloseNavMenu}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: 1,
                    }}
                >
                    <MyButton
                        component={Link}
                        to={ADD_PRODUCT_ROUTE}
                        text="Добавить продукт"
                        variant="outlined"
                        color="success"
                    />
                    <MyButton
                        component={Link}
                        to={EDIT_PRODUCT_ROUTE}
                        text="Редактировать продукт"
                        color="warning"
                        variant="outlined"
                    />
                    <MyButton
                        component={Link}
                        to={DELETE_PRODUCT_ROUTE}
                        text="Удалить продукт"
                        color="error"
                        variant="outlined"
                    />
                    <MyButton
                        component={Link}
                        to={ADD_CATEGORY_ROUTE}
                        text="Добавить категории"
                        color="success"
                        variant="outlined"
                    />
                    <MyButton
                        component={Link}
                        to={EDIT_CATEGORY_ROUTE}
                        text="Редактировать категорию"
                        color="warning"
                        variant="outlined"
                    />
                    <MyButton
                        component={Link}
                        to={DELETE_CATEGORY_ROUTE}
                        text="Удалить категорию"
                        color="error"
                        variant="outlined"
                    />
                </MenuItem>
            </Menu>
        </Box>
    )
}

export default MobileMenu
