import { createSlice } from '@reduxjs/toolkit'
import { STATUS, setStatus } from '../../../common/utils/setStatus'
import { addProduct, editProduct, getProduct, removeProduct } from './productAsync'

const initialState = {
    product: null,
    status: [],
}

const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        setProducts(state, action) {
            state.products = action.payload
        },
        setProduct(state, action) {
            state.product = action.payload
        },
        setStatusInit(state, action) {
            state.status = state.status.map((s) =>
                s.name === action.payload
                    ? {
                          name: action.payload,
                          status: STATUS.init,
                          error: null,
                      }
                    : s
            )
        },
    },
    extraReducers: (builder) => {
        // addProduct
        builder.addCase(addProduct.pending, (state, action) => {
            state.status = setStatus(state, action)
        })
        builder.addCase(addProduct.fulfilled, (state, action) => {
            state.status = setStatus(state, action)
        })
        builder.addCase(addProduct.rejected, (state, action) => {
            state.status = setStatus(state, action)
        })

        // getProduct
        builder.addCase(getProduct.pending, (state, action) => {
            state.status = setStatus(state, action)
            state.product = null
        })
        builder.addCase(getProduct.fulfilled, (state, action) => {
            state.status = setStatus(state, action)
            state.product = action.payload.product
        })
        builder.addCase(getProduct.rejected, (state, action) => {
            state.status = setStatus(state, action)
        })

        // editProduct
        builder.addCase(editProduct.pending, (state, action) => {
            state.status = setStatus(state, action)
        })
        builder.addCase(editProduct.fulfilled, (state, action) => {
            state.status = setStatus(state, action)
            // state.product = action.payload.product
        })
        builder.addCase(editProduct.rejected, (state, action) => {
            state.status = setStatus(state, action)
        })

        // removeProduct
        builder.addCase(removeProduct.pending, (state, action) => {
            state.status = setStatus(state, action)
        })
        builder.addCase(removeProduct.fulfilled, (state, action) => {
            state.status = setStatus(state, action)
            state.product = null
        })
        builder.addCase(removeProduct.rejected, (state, action) => {
            state.status = setStatus(state, action)
        })
    },
})

export const { setProducts, setProduct, setStatusInit } = productSlice.actions

export default productSlice.reducer
