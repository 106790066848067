import * as yup from 'yup'

const schemaAddProduct = yup
    .object({
        name: yup.string().required('Name product is required'),
        brand: yup.string().required('Brand is required'),
        price: yup.number().required(),
        category: yup.string().required('Category is required'),
        count: yup.number().required(),
        descriptions: yup.array().of(
            yup.object().shape({
                title: yup.string().required('Title is required'),
                paragraphs: yup
                    .array()
                    .min(1, 'At least one paragraph is required')
                    .of(yup.string().required('Paragraph content is required')),
            })
        ),
        features: yup.array().of(
            yup.object().shape({
                title: yup.string().required('Title is required'),
                feature: yup.string().required('Feature is required'),
            })
        ),
        image: yup
            .mixed()
            .test('required', 'Please select a images', (files) => {
                if (!files || files.length === 0) return false 
                for (let i = 0; i < files.length; i++) {
                    const fileType = files[i].type.split('/')[0]
                    if (fileType !== 'image') {
                        return false 
                    }
                }
                return true
            })
            .test('required', 'One or more files are too large', (files) => {
                if (!files || files.length === 0) return true 
                for (let i = 0; i < files.length; i++) {
                    const fileSizeInMb = files[i].size / 1024 / 1024
                    if (fileSizeInMb > 1) {
                        return false 
                    }
                }
                return true
            }),
    })
    .required()

export default schemaAddProduct
