import React, { useRef } from 'react'
import { Box } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { schemaAddCategory } from './schemaAddCategory'
import MyButton from '../MyButton'
import { addCategory } from '../../redux/slices/categorySlice/categoryAsync'
import ControlsInput from './ControlsInput'

export default function AddCategory() {
    const dispatch = useDispatch()
    const form = useRef()
    const { control, handleSubmit, reset } = useForm({
        resolver: yupResolver(schemaAddCategory),
    })

    const onSubmit = (data) => {
        dispatch(addCategory({ ...data }))
        reset()
    }

    return (
        <Box
            ref={form}
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                // alignItems: 'center',
                pt: 2,
                pb: 3,
                maxWidth: '350px',
            }}
        >
            <ControlsInput control={control} />
            <MyButton
                type="submit"
                text="Add"
                sx={{ minWidth: '120px', mt: 0.5 }}
            />
        </Box>
    )
}
