import React from 'react'
import { Box } from '@mui/material'
import { Controller } from 'react-hook-form'
import ControlTextField from './ControlTextField'

const ControlInput = (props) => {
    const { name, control, defaultValue = '' } = props
    const nameUpper = name.charAt(0).toUpperCase() + name.slice(1)

    return (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({ field, fieldState: { error } }) => (
                    <ControlTextField
                        {...props}
                        nameUpper={nameUpper}
                        field={field}
                        error={error}
                    />
                )}
            />
        </Box>
    )
}

export default ControlInput
