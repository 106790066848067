/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { createAsyncThunk } from '@reduxjs/toolkit'
import { deleteDoc, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore'
import {
    deleteObject,
    getDownloadURL,
    ref,
    listAll,
    uploadBytesResumable,
} from 'firebase/storage'
import { CATEGORIES, SUBCATEGORIES } from '../../../common/consts/consts'
import { db, storage } from '../../../firebase'

const removeProductPhoto = async (code) => {
    const folderRef = ref(storage, `products/${code}`)
    try {
        const res = await listAll(folderRef)

        // Deletes every file
        const deletePromises = res.items.map(async (item) => {
            const fileRef = ref(storage, item.fullPath)
            await deleteObject(fileRef)
        })

        // Wait for all file deletion operations to complete
        await Promise.all(deletePromises)
        console.log('Все фото продукта успешно удалены')
        return 1
    } catch (error) {
        console.error('Ошибка при удалении фотографий продукта:', error)
        return 0
    }
}

const upLoadPictures = async (pictures, code) => {
    await removeProductPhoto(code)
    try {
        const promisesUpLoadImg = [...pictures].map((img, i) => {
            const fileType = pictures[0].type.split('/')[1]
            const fileName = `${code}_${i}.${fileType}`
            const pathName = `/products/${code}/${fileName}`
            const refImg = ref(storage, pathName)
            const uploadTask = uploadBytesResumable(refImg, img)
            uploadTask.on(
                'state_changed',
                (snap) => {
                    const progress =
                        (snap.bytesTransferred / snap.totalBytes) * 100
                    console.log(`Upload ${fileName} is ${progress}% done`)
                },
                (error) => console.error('Error upload file -', pathName, error)
            )
            return uploadTask
        })
        const snapshots = await Promise.all(promisesUpLoadImg)
        const urlsPromise = snapshots.map((snap) =>
            getDownloadURL(snap.ref).then((downloadURL) => downloadURL)
        )
        const downloadUrls = await Promise.all(urlsPromise)
        return { downloadUrls, snapshots }
    } catch (e) {
        console.log(e)
        return ''
    }
}

export const addProduct = createAsyncThunk(
    'products/addProduct',
    async (dataForm, { thunkAPI }) => {
        try {
            const { image, code, category, subcategory } = dataForm
       
            const { downloadUrls } = await upLoadPictures(image, code)
           
            const productData = {
                discount: 0,
                ...dataForm,
                image: downloadUrls,
                category: doc(db, CATEGORIES, category),
            }
            if (subcategory)
                productData.subcategory = doc(db, SUBCATEGORIES, subcategory)
            await setDoc(doc(db, 'products', `${code}`), productData)
            console.log(`Added product success! Code: ${code}`)
            return productData
            // return 0
        } catch (e) {
            console.error('(clg) Added product error!', e)
            return thunkAPI.rejectWithValue(e)
        }
    }
)

export const getProduct = createAsyncThunk(
    'product/getProduct',
    async (data, { rejectWithValue }) => {
        try {
            console.log(data)
            const docRef = doc(db, 'products', `${data.code}`)
            const docSnap = await getDoc(docRef)
          
            const product = docSnap.data() || null
            
            if (!product) throw new Error('Нет продукта с таким кодом')
            const categoryProduct =
                product.category && (await getDoc(product.category))
            const subCategoryProduct =
                product.subcategory && (await getDoc(product.subcategory))
            product.category = categoryProduct?.data() || ''
            product.subcategory = subCategoryProduct?.data() || ''

            console.log('Данные полученного продукта:', product)
            return { product }
        } catch (e) {
            console.log('ERROR getProduct', e)
            return rejectWithValue(e)
        }
    }
)

export const editProduct = createAsyncThunk(
    'product/editProduct',
    async (data, { thunkAPI }) => {
        try {
            const { formData, isLoadImg } = data
            const docRef = doc(db, 'products', `${formData.code}`)

            const docCategory = formData.category
                ? doc(db, CATEGORIES, formData?.category)
                : null
            const docSubCategory = formData?.subcategory
                ? doc(db, SUBCATEGORIES, formData.subcategory)
                : null

            let updatedProduct = {
                ...formData,
                category: docCategory,
                subcategory: docSubCategory,
            }

            
            if (isLoadImg) {
                const { downloadUrls } = await upLoadPictures(
                    formData.image,
                    formData.code
                )

                updatedProduct = {
                    ...updatedProduct,
                    image: [...downloadUrls],
                }
                await updateDoc(docRef, updatedProduct)
                return { product: updatedProduct }
            }

            await updateDoc(docRef, updatedProduct)
            return { product: updatedProduct }
        } catch (e) {
            console.log(e)
            return thunkAPI.rejectWithValue(e)
        }
    }
)

export const removeProduct = createAsyncThunk(
    'product/removeProduct',
    async (productCode, { thunkAPI }) => {
        try {
            await removeProductPhoto(productCode)
            await deleteDoc(doc(db, 'products', `${productCode}`))

            console.log('Продукт успешно удалён')
            return null
        } catch (e) {
            console.log(e)
            return thunkAPI.rejectWithValue(e)
        }
    }
)
