/* eslint-disable no-console */
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { CACHE_SIZE_UNLIMITED, initializeFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}

const firebaseApp = initializeApp(firebaseConfig)

const auth = getAuth(firebaseApp)
const storage = getStorage(firebaseApp)

const db = initializeFirestore(firebaseApp, {
    cacheSizeBytes: CACHE_SIZE_UNLIMITED,
    experimentalForceLongPolling: true, // this line
  useFetchStreams: false, // and this line
})

export { auth, db, storage }
