import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'


export const getAuthAdminUser = createAsyncThunk(
    'userAdmin/getAuthUser',
    async ({ email, password }, { thunkAPI }) => {
        try {
            const auth = getAuth()
            
            const singInData = await signInWithEmailAndPassword(
                auth,
                email,
                password
            )
            return singInData.user
        } catch (e) {
            return thunkAPI.rejectWithValue(e)
        }
    }
)

export const logOutUserAdmin = createAsyncThunk(
    'userAdmin/logOutUserAdmin',
    async (_, { thunkAPI }) => {
        try {
            const auth = getAuth()
            await auth.signOut()
            return 'data'
        } catch (e) {
            return thunkAPI.rejectWithValue(e)
        }
    }
)
