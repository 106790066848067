import React from 'react'
import { Box, styled } from '@mui/material'
import { format } from 'date-fns'
import { Timestamp } from 'firebase/firestore'
import useMyTheme from '../../common/hooks/useMyTheme'
import AutocompleteController from '../ControlInput/AutocompleteController'
import ControlInput from '../ControlInput/ControlInput'

const CustomBox = styled(Box)(({ theme }) => {
    const { mq } = useMyTheme()
    return {
        display: 'flex',
        gap: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        width: '100%',
        flexWrap: mq && 'wrap',
    }
})

const MainBlock = ({ control, categories, subcategories }) => {
    return (
        <>
            <ControlInput autoComplete="on" name="name" control={control} />
            <ControlInput autoComplete="on" name="brand" control={control} />
            <CustomBox>
                <ControlInput
                    autoComplete="on"
                    name="price"
                    control={control}
                    pb={0}
                />
                <ControlInput
                    autoComplete="on"
                    name="count"
                    label="Count product in stock"
                    control={control}
                    pb={0}
                />
            </CustomBox>
            <CustomBox>
                <AutocompleteController
                    control={control}
                    name="category"
                    options={categories}
                    label="Main category"
                    fullWidth
                />
                <AutocompleteController
                    control={control}
                    name="subcategory"
                    options={subcategories}
                    label="Subcategories"
                    fullWidth
                />
            </CustomBox>
            <CustomBox>
                <ControlInput
                    autoComplete="on"
                    name="dateCreated"
                    control={control}
                    defaultValue={format(
                        Timestamp.fromDate(new Date()).toDate(),
                        'dd-MM-yyyy hh:mm a'
                    )}
                    TextFieldProps={{ disabled: true }}
                />
                <ControlInput
                    autoComplete="on"
                    name="code"
                    control={control}
                    TextFieldProps={{ disabled: true }}
                />
            </CustomBox>
        </>
    )
}

export default MainBlock
