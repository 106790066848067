import { Box, Button } from '@mui/material'
import React, { useEffect } from 'react'
import { useFieldArray } from 'react-hook-form'
import DescriptionField from './DescriptionField'

const DescriptionBlock = ({
    control,
    checkReset,
}) => {
    const { fields, append, remove, replace } = useFieldArray({
        control,
        name: 'descriptions',
    })
    useEffect(() => {
        if (fields.length === 0) append({ title: '', paragraphs: [''] })
    }, [])

    useEffect(() => {
        if (fields.length !== 0) replace()
    }, [checkReset])
    // editInputName={editInputName}
    // handleClickEditInput={handleClickEditInput}
    return (
        <Box display="flex" flexDirection="column">
            {fields.map((field, index) => (
                <DescriptionField
                    key={field.id}
                    control={control}
                    name={`descriptions[${index}]`}
                    field={field}
                    onRemove={() => remove(index)}
                />
            ))}
            <Button
                variant="outlined"
                color="primary"
                sx={{ maxWidth: '200px', mt: 2, alignSelf: 'center' }}
                onClick={() => append({ title: '', paragraphs: [''] })}
            >
                Add Description
            </Button>
        </Box>
    )
}

export default DescriptionBlock
