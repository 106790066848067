import React from 'react'
import { Link } from 'react-router-dom'
import MyButton from '../MyButton'

const ButtonPage = ({ to, text, color = 'success' }) => {
    return (
        <MyButton
            component={Link}
            to={to}
            text={text}
            variant="contained"
            color={color}
            textColor="white"
            
        />
    )
}

export default ButtonPage
