import { Box, Button } from '@mui/material'
import React, { useEffect } from 'react'
import { useFieldArray } from 'react-hook-form'
import FeatureField from './FeatureField'

const FeaturesBlock = ({ control, checkReset }) => {
    const { fields, append, remove, replace, update } = useFieldArray({
        control,
        name: 'features',
    })

    useEffect(() => {
        if (fields.length === 0) append({ title: '', feature: '' })

        // Костыль, создается field c null либо без полей title и feature
        // по этому приходиться обновлять этим  действием
        // это происходил либо при заходе на страницу либо при спросе
        if (fields[0]?.title === undefined || fields[0]?.title === null)
            update(0, { title: '', feature: '' })
    }, [fields])

    useEffect(() => {
        if (fields.length !== 0) replace()
    }, [checkReset])

    if (fields[0]?.title === null) {
        return null
    }

    if (fields[0]?.title === undefined || fields[0]?.title === null) {
        return null
    }

    return (
        <Box display="flex" flexDirection="column">
            {fields[0]?.title !== null &&
                fields.map((field, index) => (
                    <FeatureField
                        key={field.id}
                        control={control}
                        name={`features[${index}]`}
                        field={field}
                        onRemove={() => remove(index)}
                    />
                ))}
            <Button
                variant="outlined"
                color="primary"
                sx={{ maxWidth: '200px', mt: 2, alignSelf: 'center' }}
                onClick={() => append({ title: '', feature: '' })}
            >
                Add Feature
            </Button>
        </Box>
    )
}

export default FeaturesBlock
