import React from 'react'
import ControlInput from '../ControlInput/ControlInput'

const ControlsInput = ({ control }) => {
    return (
        <>
            <ControlInput
                autoComplete="on"
                name="ukName"
                label="UA Name category"
                control={control}
                pb={0}
                sx={{ width: '100%' }}
            />
            <ControlInput
                autoComplete="on"
                name="enName"
                label="EN Name category"
                control={control}
                pb={0}
                sx={{ width: '100%' }}
            />
            <ControlInput
                autoComplete="on"
                name="ruName"
                label="RU Name category"
                control={control}
                pb={0}
                sx={{ width: '100%' }}
            />
        </>
    )
}

export default ControlsInput
