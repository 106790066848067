/* eslint-disable max-len */
export default function generateProductCode() {
    const code = Math.floor(Math.random() * 1000000) // генерация случайного 6-значного числа
    let sum = 0
    let temp = code

    while (temp > 0) {
        // вычисление суммы цифр 6-значного числа
        sum += temp % 10
        temp = Math.floor(temp / 10)
    }

    const checkDigit = (10 - (sum % 10)) % 10 // вычисление контрольной цифры

    return code * 10 + checkDigit // соединение 6-значного числа и контрольной цифры для получения 7-значного кода товара
}
