import { createSlice } from '@reduxjs/toolkit'
import { setStatus } from '../../../common/utils/setStatus'
import {
    addCategory,
    addSubcategory,
    getCategoriesSubcategories,
} from './categoryAsync'

const initialState = {
    categories: [],
    subcategories: [],
    status: [],
}

const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // addCategory
        builder.addCase(addCategory.pending, (state, action) => {
            state.status = setStatus(state, action)
        })
        builder.addCase(addCategory.fulfilled, (state, action) => {
            state.status = setStatus(state, action)
        })
        builder.addCase(addCategory.rejected, (state, action) => {
            state.status = setStatus(state, action)
        })

        // addSubcategory
        builder.addCase(addSubcategory.pending, (state, action) => {
            state.status = setStatus(state, action)
        })
        builder.addCase(addSubcategory.fulfilled, (state, action) => {
            state.status = setStatus(state, action)
        })
        builder.addCase(addSubcategory.rejected, (state, action) => {
            state.status = setStatus(state, action)
        })
        // getCategoriesSubCategories
        builder.addCase(getCategoriesSubcategories.pending, (state, action) => {
            state.status = setStatus(state, action)
        })
        builder.addCase(
            getCategoriesSubcategories.fulfilled,
            (state, action) => {
                state.status = setStatus(state, action)
                state.categories = action.payload.categories
                state.subcategories = action.payload.subcategories
            }
        )
        builder.addCase(
            getCategoriesSubcategories.rejected,
            (state, action) => {
                state.status = setStatus(state, action)
            }
        )
    },
})

// export const {} = categorySlice.actions

export default categorySlice.reducer
