export const ROUTE = '/'
export const LOGIN_ROUTE = '/login'

export const ADD_PRODUCT_ROUTE = '/add-product'
export const EDIT_PRODUCT_ROUTE = '/edit-product'
export const DELETE_PRODUCT_ROUTE = '/delete-product'

export const ADD_CATEGORY_ROUTE = '/add-category'
export const EDIT_CATEGORY_ROUTE = '/edit-category'
export const DELETE_CATEGORY_ROUTE = '/delete-category'
