import { createAsyncThunk } from '@reduxjs/toolkit'
import { collection, doc, getDocs, setDoc } from 'firebase/firestore'
import { uid } from 'uid'
import { CATEGORIES, SUBCATEGORIES } from '../../../common/consts/consts'
import { db } from '../../../firebase'

export const getCategoriesSubcategories = createAsyncThunk(
    'category/getCategoriesSubCategories',
    async (thunkAPI) => {
        try {
            const querySnapCAT = await getDocs(collection(db, CATEGORIES))
            const querySnapSUB = await getDocs(collection(db, SUBCATEGORIES))

            const categories = []
            const subcategories = []

            querySnapCAT.forEach((doc) => categories.push(doc.data()))
            querySnapSUB.forEach((doc) => subcategories.push(doc.data()))

            return { categories, subcategories }
        } catch (e) {
            return thunkAPI.rejectWithValue(e)
        }
    }
)

export const addCategory = createAsyncThunk(
    'category/addCategory',
    async (data, { thunkAPI, dispatch }) => {
        const { enName, ukName, ruName } = data
        const nameDoc = enName.trim().toLowerCase().split(' ').join('-')

        try {
            await setDoc(doc(db, CATEGORIES, nameDoc), {
                enName,
                ukName,
                ruName,
                nameDoc,
                id: uid(),
                image: '',
                sortIndex: 99,
            })
            dispatch(getCategoriesSubcategories())
            return CATEGORIES
        } catch (e) {
            return thunkAPI.rejectWithValue(e)
        }
    }
)

export const addSubcategory = createAsyncThunk(
    'category/addSubcategory',
    async (data, { thunkAPI, dispatch }) => {
        try {
            const docRef = doc(db, CATEGORIES, data.docNameCategory)
            const { enName, ukName, ruName } = data
            const nameDoc = enName.trim().toLowerCase().split(' ').join('-')
            
            await setDoc(doc(db, SUBCATEGORIES, nameDoc), {
                enName,
                ukName,
                ruName,
                nameDoc,
                id: uid(),
                image: '',
                category: docRef,
                sortIndex: 99,
            })
            dispatch(getCategoriesSubcategories())

            return CATEGORIES
        } catch (e) {
            return thunkAPI.rejectWithValue(e)
        }
    }
)
