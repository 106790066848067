import { Button } from '@mui/material'
import React from 'react'

const MyButton = ({
    text = 'Button',
    type = 'button',
    variant = 'contained',
    color = 'primary',
    size = 'large',
    textColor = false,
    fullWidth = false,
    upperCase = false,
    sx = {},
    ...otherProps
}) => {
    const checkColor = variant === 'contained' ? 'white' : 'text.primary'
    return (
        <Button
            type={type}
            variant={variant}
            color={color}
            size={size}
            sx={{
                padding: 1,
                width: fullWidth ? '100%' : 'initial',
                color: textColor || checkColor,
                textTransform: upperCase ? 'uppercase' : 'initial',
                textDecoration: 'none',
                fontSize: '14px',
                ...sx,
            }}
            
            {...otherProps}
            
        >
            {text}
        </Button>
    )
}

export default MyButton
