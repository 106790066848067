import { LoadingButton } from '@mui/lab'
import SendIcon from '@mui/icons-material/Send'
import { Box, TextField } from '@mui/material'
import React from 'react'

const SearchBlock = ({
    codeProduct,
    setCodeProduct,
    handleSearchProduct,
    isLoading,
}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                gap: 2,
                alignItems: 'center',
                maxWidth: '300px',
                pb: 2,
            }}
        >
            <TextField
                id=""
                label="Код продукта"
                value={codeProduct}
                sx={{ minWidth: '150px' }}
                onChange={(e) => setCodeProduct(e.target.value)}
            />
            <LoadingButton
                sx={{ color: 'white', height: '100%' }}
                fullWidth
                onClick={() => handleSearchProduct()}
                endIcon={<SendIcon />}
                loading={isLoading}
                loadingPosition="end"
                variant="contained"
            >
                Поиск
            </LoadingButton>
            
        </Box>
    )
}

export default SearchBlock
