import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import React, { useEffect, useRef, useState } from 'react'

const ImagesBlock = ({ files, product }) => {
    const imgRef = useRef(null)

    const [width, setWidth] = useState(null)

    useEffect(() => {
        function handleResize() {
            if (imgRef.current) {
                setWidth(imgRef.current.offsetWidth)
            }
        }

        window.addEventListener('resize', handleResize)
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <Grid container spacing={1}>
            {files.length > 0 &&
                files.map((file) => (
                    <Grid xs={6} sm={4} md={4} lg={3} key={file}>
                        <Card
                            sx={(theme) => ({
                                maxWidth: '100%',
                                border: '1px solid rgba(230, 230, 230, 1)',
                                borderRadius: '10px',
                                p: 1,
                                '&:hover': {
                                    boxShadow: theme.shadows[4],
                                },
                            })}
                            elevation={0}
                        >
                            <Box
                                ref={imgRef}
                                pb={1}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <img height={width} src={file} alt="" />
                            </Box>
                            {/* <CardMedia
                ref={elementRef}
                sx={{ height: width }}
                image={product.img[0]}
                title="green iguana"
            /> */}
                            <Divider />
                            <CardContent sx={{ p: 2, pb: 0 }}>
                                <Typography
                                    gutterBottom
                                    variant="p"
                                    component="p"
                                    sx={{
                                        fontSize: '15px',
                                        fontWeight: 600,
                                        color: '#4d4d4d',
                                    }}
                                >
                                    {product.name}
                                </Typography>
                                <Typography
                                    gutterBottom
                                    variant="p"
                                    component="p"
                                    color="primary"
                                    sx={{
                                        fontSize: '15px',
                                        fontWeight: 600,
                                    }}
                                >
                                    {product.price}$
                                </Typography>
                            </CardContent>
                            <CardActions
                                sx={{ display: 'flex', gap: 1 }}
                                disableSpacing
                            >
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        // color: 'white',
                                        fontWeight: 600,
                                        fontSize: '13px',
                                        width: '100%',
                                    }}
                                >
                                    <AddShoppingCartIcon
                                        sx={{
                                            color: 'white',
                                            fontSize: '23px',
                                            height: '100%',
                                        }}
                                    />
                                </Button>

                                <Button size="small" variant="contained">
                                    <FavoriteBorderIcon
                                        sx={{
                                            color: 'white',
                                            fontSize: '23px',
                                            height: '100%',
                                        }}
                                    />
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
        </Grid>
    )
}

export default ImagesBlock
