import {
    ADD_CATEGORY_ROUTE,
    ADD_PRODUCT_ROUTE,
    DELETE_PRODUCT_ROUTE,
    EDIT_CATEGORY_ROUTE,
    EDIT_PRODUCT_ROUTE,
    LOGIN_ROUTE,
    ROUTE,
} from './common/consts/ROUTES'
import AddCategoryPage from './page/AddCategoryPage'
import AddProduct from './page/AddProduct'
import DeleteProduct from './page/DeleteProduct'
import EditCategory from './page/EditCategory'
import EditProduct from './page/EditProduct'
import Home from './page/Home'
import Login from './page/Login'

export const publicRoutesAdmin = [
    {
        path: LOGIN_ROUTE,
        Component: <Login />,
    },
]

export const privateRoutesAdmin = [
    {
        path: ROUTE,
        Component: <Home />,
    },
    {
        path: ADD_PRODUCT_ROUTE,
        Component: <AddProduct />,
    },
    {
        path: ADD_CATEGORY_ROUTE,
        Component: <AddCategoryPage />,
    },
    {
        path: EDIT_PRODUCT_ROUTE,
        Component: <EditProduct />,
    },
    {
        path: DELETE_PRODUCT_ROUTE,
        Component: <DeleteProduct />,
    },
    {
        path: EDIT_CATEGORY_ROUTE,
        Component: <EditCategory />,
    },
]
