import { Navigate, Route, Routes } from 'react-router-dom'
import { LOGIN_ROUTE, ROUTE } from './common/consts/ROUTES'
import { privateRoutesAdmin, publicRoutesAdmin } from './routes'

const AppRouterAdmin = ({ isAuth }) => {
    return isAuth ? (
        <Routes>
            {privateRoutesAdmin.map(({ path, Component }) => (
                <Route key={path} path={path} element={Component} />
            ))}

            <Route path="*" element={<Navigate to={ROUTE} replace />} />
        </Routes>
    ) : (
        <Routes>
            {publicRoutesAdmin.map(({ path, Component }) => (
                <Route key={path} path={path} element={Component} />
            ))}
            <Route path="*" element={<Navigate to={LOGIN_ROUTE} replace />} />
        </Routes>
    )
}

export default AppRouterAdmin
