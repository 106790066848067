import { Container, Divider, Typography } from '@mui/material'
import React from 'react'
import AddCategory from '../components/AddCategory/AddCategory'
import AddSubcategory from '../components/AddCategory/AddSubcategory'

const AddCategoryPage = () => {
    return (
        <Container maxWidth="md">
            <Typography variant="h4" color="initial">
                Add Category
            </Typography>
            <Divider />
            <AddCategory />
            <Typography variant="h4" color="initial">
                Add Subcategory
            </Typography>
            <Divider />
            <AddSubcategory />
        </Container>
    )
}

export default AddCategoryPage
