import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Container from '@mui/material/Container'
import { useDispatch } from 'react-redux'
import { logOutUserAdmin } from '../../redux/slices/userSlice/userAsync'
import {
    ADD_CATEGORY_ROUTE,
    ADD_PRODUCT_ROUTE,
    DELETE_CATEGORY_ROUTE,
    DELETE_PRODUCT_ROUTE,
    EDIT_CATEGORY_ROUTE,
    EDIT_PRODUCT_ROUTE,
} from '../../common/consts/ROUTES'
import LogoBlockAdmin from './LogoBlockAdmin'
import useMyTheme from '../../common/hooks/useMyTheme'
import MobileMenu from './MobileMenu'
import LogOut from './LogOut'
import ButtonPage from './ButtonPage'

const MyAppBar = () => {
    const dispatch = useDispatch()
    const { mq } = useMyTheme()
    const [anchorElNav, setAnchorElNav] = React.useState(null)
    const [anchorElUser, setAnchorElUser] = React.useState(null)

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget)
    }
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }

    const handleLogOut = () => {
        dispatch(logOutUserAdmin())
        handleCloseUserMenu()
    }

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar
                    disableGutters
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    {!mq && <LogoBlockAdmin />}

                    {mq && (
                        <MobileMenu
                            handleOpenNavMenu={handleOpenNavMenu}
                            anchorElNav={anchorElNav}
                            handleCloseNavMenu={handleCloseNavMenu}
                        />
                    )}

                    {mq && <LogoBlockAdmin mobile />}

                    <Box p={1}>
                        <Box
                            sx={{
                                display: { xs: 'none', md: 'flex', gap: 10 },
                            }}
                        >
                            <ButtonPage
                                to={ADD_PRODUCT_ROUTE}
                                text="Добавить продукт"
                            />
                            <ButtonPage
                                to={EDIT_PRODUCT_ROUTE}
                                text="Редактировать продукт"
                                color="warning"
                            />
                            <ButtonPage
                                to={DELETE_PRODUCT_ROUTE}
                                text="Удалить продукт"
                                color="error"
                            />
                            <ButtonPage
                                to={ADD_CATEGORY_ROUTE}
                                text="Добавить категории"
                            />
                            <ButtonPage
                                to={EDIT_CATEGORY_ROUTE}
                                text="Редактировать категорию"
                                color="warning"
                            />
                            <ButtonPage
                                to={DELETE_CATEGORY_ROUTE}
                                text="Удалить категорию"
                                color="error"
                            />
                        </Box>
                    </Box>

                    <LogOut
                        handleOpenUserMenu={handleOpenUserMenu}
                        anchorElUser={anchorElUser}
                        handleLogOut={handleLogOut}
                        handleCloseUserMenu={handleCloseUserMenu}
                    />
                </Toolbar>
            </Container>
        </AppBar>
    )
}
export default MyAppBar
