import React from 'react'
import { Box, Button } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import DeleteIcon from '@mui/icons-material/Delete'
import ControlInput from '../../ControlInput/ControlInput'
 
const FeatureField = ({ control, name, onRemove }) => {
    return (
        <Box
            sx={(theme) => ({
                padding: 1,
                background: theme.palette.background.lightGrey
            })}
        >
            <Grid spacing={1}>
                <Grid container spacing={1}>
                    <Grid xs={12} md={4}>
                        <ControlInput
                            autoComplete="on"
                            name={`${name}.title`}
                            control={control}
                            label="Title"
                            sx={{ background: 'white' }}
                            pb={0}
                        />
                    </Grid>
                    <Grid xs={12} md={7}>
                        <ControlInput
                            autoComplete="on"
                            name={`${name}.feature`}
                            control={control}
                            label="Feature"
                            sx={{ background: 'white' }}
                            pb={0}
                        />
                    </Grid>
                    <Grid xs={3} md={1} alignSelf="center">
                        <Button
                            variant="contained"
                            color="error"
                            onClick={onRemove}
                            sx={{ mb: 1 }}
                        >
                            <DeleteIcon />
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default FeatureField
