import { Chip, Divider } from '@mui/material'
import React from 'react'

const MyDividerChip = ({ color = 'info', label = 'Chip' }) => {
    return (
        <Divider sx={{ py: 2 }}>
            <Chip color={color} label={label} sx={{ fontSize: '16px' }} />
        </Divider>
    )
}

export default MyDividerChip
