import { Autocomplete, TextField } from '@mui/material'
import { Controller } from 'react-hook-form'

const AutocompleteController = ({
    control,
    name,
    options,
    label,
    fullWidth = false,
    sxTextField = {},
    editInputName,
}) => {
    const updateOptions =
        options &&
        options.map((cat) => ({
            label: cat.ukName,
            value: cat.nameDoc,
        }))

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={null}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
                return (
                    <Autocomplete
                        // disabled={editInputName !== 'category'}
                        options={updateOptions}
                        value={
                            updateOptions.find(
                                (option) => option.value === value
                            ) || null
                        }
                        inputValue={
                            updateOptions.find(
                                (option) => option.value === value
                            )?.label || ''
                        }
                        fullWidth={fullWidth}
                        onChange={(event, newValue) => {
                            onChange(newValue?.value || null)
                        }}
                        isOptionEqualToValue={(option, value) =>
                            option.value === value.value
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                sx={{
                                    minWidth: '200px',
                                    boxShadow:
                                        editInputName === 'category' &&
                                        '0px 0px 5px 1px orange',
                                    ...sxTextField,
                                }}
                                label={label}
                                title="sd"
                                variant="outlined"
                                error={!!error}
                                helperText={error ? error.message : null}
                            />
                        )}
                    />
                )
            }}
        />
    )
}

export default AutocompleteController
