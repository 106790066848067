import { Box, Container, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import DeleteIcon from '@mui/icons-material/Delete'
import SearchBlock from '../components/EditProductTest/SearchBlock'
import {
    getProduct,
    removeProduct,
} from '../redux/slices/productSlice/productAsync'
import { STATUS } from '../common/utils/setStatus'
import CardItemProduct from '../components/DeleteProduct/CardItemProduct'

import {
    setProduct,
    setStatusInit,
} from '../redux/slices/productSlice/productSlice'
import checkStatus from '../common/utils/checkStatus'

const DeleteProduct = () => {
    const dispatch = useDispatch()

    const [codeProduct, setCodeProductValue] = useState('')
    const { status, product } = useSelector(({ product }) => product)
    const handleSearchProduct = () => {
        if (codeProduct?.length > 4 && codeProduct?.length < 11)
            dispatch(getProduct({ code: codeProduct.trim() }))
    }

    useEffect(() => {
        return () => {
            dispatch(setProduct(null))
            dispatch(setStatusInit('getProduct'))
        }
    }, [])

    const handleRemoveProduct = (code) => {
        dispatch(removeProduct(code))
    }

    const productStatus = checkStatus(status, 'getProduct')
    const productLoading = productStatus?.status === STATUS.loading
    const productError = productStatus?.status === STATUS.error

    const deleteLoading =
        checkStatus(status, 'removeProduct')?.status === STATUS.loading

    return (
        <Container maxWidth="md">
            <Typography variant="h3" color="initial" pb={2}>
                Удаление продукта
            </Typography>

            <SearchBlock
                codeProduct={codeProduct}
                setCodeProduct={setCodeProductValue}
                handleSearchProduct={handleSearchProduct}
                isLoading={productLoading}
            />
            {productError && (
                <Typography variant="h6" color="error" sx={{ fontSize: 12 }}>
                    {productStatus?.error?.payload?.message}
                </Typography>
            )}
            {product && (
                <Box
                    sx={(theme) => ({
                        display: 'inline-flex',
                        gap: 1,
                        alignItems: 'flex-start',
                        background: theme.palette.background.lightGrey,
                        padding: 1,
                    })}
                >
                    <Box sx={{ maxWidth: '200px' }}>
                        <CardItemProduct product={product} />
                    </Box>
                    {/* <MyButton
                        text={`Удалить этот продукт - ${product.code}`}
                        color="error"
                        sx={{ maxWidth: '150px' }}
                        onClick={() => handleRemoveProduct(product.code)}
                    /> */}
                    <LoadingButton
                        sx={{
                            color: 'white',
                            height: '100%',
                            maxWidth: '200px',
                        }}
                        // fullWidth
                        onClick={() => handleRemoveProduct(product.code)}
                        endIcon={<DeleteIcon />}
                        loading={deleteLoading}
                        loadingPosition="end"
                        color="error"
                        variant="contained"
                    >
                        Удалить этот продукт - {product.code}
                    </LoadingButton>
                </Box>
            )}
        </Container>
    )
}

export default DeleteProduct
