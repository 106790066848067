import { Container, Typography } from '@mui/material'
import React from 'react'

const Home = () => {

    

    return (
        <Container>
            <Typography variant="h3" color="initial" textAlign="center">
                HomeAdmin
            </Typography>
        </Container>
    )
}

export default Home
