import { yupResolver } from '@hookform/resolvers/yup'
import SendIcon from '@mui/icons-material/Send'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Button, Container, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { doc, getDoc } from 'firebase/firestore'
import { useDispatch, useSelector } from 'react-redux'
import DescriptionBlock from '../components/AddProduct/DescriptionBlock/DescriptionBlock'
import FeaturesBlock from '../components/AddProduct/FeaturesBlock/FeaturesBlock'
import ImagesBlock from '../components/AddProduct/ImagesBlock'
import MainBlock from '../components/AddProduct/MainBlock'
import schemaAddProduct from '../components/AddProduct/schemaAddProduct'
import MyButton from '../components/MyButton'
import MyDividerChip from '../components/MyDividerChip'
import { db } from '../firebase'
import { CATEGORIES } from '../common/consts/consts'
import generateProductCode from '../common/utils/generateProductCode'
import { addProduct } from '../redux/slices/productSlice/productAsync'
import { STATUS } from '../common/utils/setStatus'
import { setStatusInit } from '../redux/slices/productSlice/productSlice'

const AddProduct = () => {
    const form = useRef()
    const dispatch = useDispatch()
    const [files, setFiles] = useState([])
    const [checkReset, setCheckReset] = useState(false)
    const [filteredSubcategories, setFilteredSubcategories] = useState([])

    const { categories, subcategories } = useSelector(
        ({ category }) => category
    )

    const { status } = useSelector(({ product }) => product)

    const { status: statusAdded } =
        status.length && status.find((el) => el.name)

    const {
        control,
        formState: { errors },
        handleSubmit,
        register,
        getValues,
        watch,
        setValue,
        reset,
        resetField,
    } = useForm({
        resolver: yupResolver(schemaAddProduct),
    })

    const watchFieldsImage = watch('image')
    const watchFieldsCategory = watch('category')
    const watchFields = watch()

    const restForm = () => {
        reset()
        window.sessionStorage.setItem('dataFromProduct', null)
        setCheckReset(!checkReset)
        setFiles([])
        setCodeProduct()
    }

    useEffect(() => {
        if (statusAdded === STATUS.success) {
            restForm()
            dispatch(setStatusInit('addProduct'))
        }
    }, [status])

    useEffect(() => {
        return () => {
            reset()
            setCheckReset(!checkReset)
            setFiles([])
        }
    }, [])

    const restSubcategory = () => {
        if (getValues('subcategory')) {
            resetField('subcategory')
        }
    }

    const onSubmit = (data) => {
        if (!data.code) return
        dispatch(addProduct(data))
    }

    const setCodeProduct = async () => {
        const code = generateProductCode()
        const docRef = doc(db, 'products', `${code}`)
        const docSnap = await getDoc(docRef)
        if (!docSnap.data()) {
            setValue('code', generateProductCode())
        } else {
            setCodeProduct()
        }
    }

    // Subcategory filter, with the selected category
    useEffect(() => {
        const checkCategory =
            categories?.length && subcategories?.length && getValues().category

        setFilteredSubcategories(
            checkCategory
                ? subcategories.filter(
                      (el) =>
                          el.category.path ===
                          doc(db, CATEGORIES, getValues().category).path
                  )
                : []
        )
        restSubcategory()
    }, [watchFieldsCategory])

    // Restoration of these forms, after returning to the page
    useEffect(() => {
        const str = window.sessionStorage.getItem('dataFromProduct')
        const values = JSON.parse(str)

        if (values) {
            const valueKeys = Object.keys(values)
            valueKeys.forEach(
                (key) => key !== 'image' && setValue(key, values[key])
            )
        }
    }, [])

    // Preservation of form data in sessionStorage
    useEffect(() => {
        const str = JSON.stringify(getValues())
        window.sessionStorage.setItem('dataFromProduct', str)
    }, [watchFields])

    // filelist processing to display a photo of the product
    useEffect(() => {
        if (getValues()?.image.length) {
            const fileList = getValues().image

            const images = []

            for (let i = 0; i < fileList.length; i++) {
                const file = fileList[i]
                const imageUrl = URL.createObjectURL(file)
                images.push(imageUrl)
            }

            setFiles((prev) => {
                if (prev.length !== images.length) {
                    return images
                }
                return prev
            })
        }
    }, [watchFieldsImage])

    // Installation of a unique product code
    useEffect(() => {
        setTimeout(() => {
            if (!getValues().code.toString().trim()) {
                setCodeProduct()
            }
        })
    }, [])

    return (
        <Container maxWidth="md">
            <Typography variant="h3" color="initial" pb={2}>
                Add product
            </Typography>

            <Box
                ref={form}
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
            >
                {/* Main */}
                <MyDividerChip label="Main" />
                <MainBlock
                    control={control}
                    categories={categories}
                    subcategories={filteredSubcategories}
                    setValue={setValue}
                    getValues={getValues}
                />
                {/* Features */}
                <MyDividerChip label="Features" />
                <FeaturesBlock
                    control={control}
                    checkReset={checkReset}
                    setCheckReset={setCheckReset}
                />
                {/* Description */}
                <MyDividerChip label="Description" />
                <DescriptionBlock
                    control={control}
                    checkReset={checkReset}
                    setCheckReset={setCheckReset}
                />
                {/* Images */}
                <MyDividerChip label="Images" />
                <Button
                    variant="contained"
                    component="label"
                    color="inherit"
                    sx={{
                        color: 'black',
                        width: '100%',
                    }}
                >
                    <input
                        {...register('image')}
                        type="file"
                        accept="image/*"
                        name="image"
                        multiple
                        hidden
                    />
                    Upload images
                </Button>
                {errors?.image && (
                    <Typography
                        variant="h6"
                        color="error"
                        sx={{ fontSize: 12 }}
                    >
                        {errors.image.message}
                    </Typography>
                )}

                {files.length > 0 && (
                    <>
                        <Typography variant="h4" color="text.secondary" pb={2}>
                            The approximate example of what pictures in the
                            cards will look like
                        </Typography>
                        <ImagesBlock files={files} product={getValues()} />
                    </>
                )}

                <MyDividerChip label="Send" />
                <Grid container spacing={1}>
                    <Grid xs={4}>
                        <MyButton
                            text="Rest form"
                            fullWidth
                            color="error"
                            onClick={() => restForm()}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid xs={8}>
                        <LoadingButton
                            sx={{ height: '100%', color: 'white' }}
                            fullWidth
                            type="submit"
                            endIcon={<SendIcon />}
                            loading={statusAdded === 'loading'}
                            loadingPosition="end"
                            variant="contained"
                        >
                            <span>Send</span>
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}

export default AddProduct
